using System.Collections.Generic;
using System.Linq;
using Bridge.jQuery2;

namespace Bridge.Navigation
{
    public static class Utility
    {
        /// <summary>
        /// Load script sequentially
        /// </summary>
        /// <param name="scripts"></param>
        public static void SequentialScriptLoad(List<string> scripts)
        {
            if (!System.Linq.Enumerable.Any<string>(scripts)) return;
            var toLoad = System.Linq.Enumerable.First<string>(scripts);
            jQuery.GetScript(toLoad, (global::System.Action<object, string, global::Bridge.jQuery2.jqXHR>)((o, s, arg3) =>
            {
                scripts.Remove(toLoad);
                SequentialScriptLoad(scripts);
            }));
        }
    }
}